import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { IoIosSearch } from 'react-icons/io';
import classes from './Pagination.module.css';
import { BsFillPlayFill } from 'react-icons/bs';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { MdOutlineArrowOutward } from 'react-icons/md';

const Pagination = ({ data, dataLimit, loadMore }) => {
  const [videoIndex, setVideoIndex] = useState(null);
  const [searchResult, setSearchResult] = useState('');

  const getPaginatedData = () => {
    if (searchResult !== '') {
      return data.filter((course) =>
        course.name.toLowerCase().includes(searchResult.toLowerCase())
      ).slice(0, dataLimit);
    }
    return data.slice(0, dataLimit);
  };

  return (
    <section className={classes['courses-container']}>
      <div className={classes['courses-container-header']}>
        <h2>Our Highly-Requested Courses</h2>

        <div className={classes['search-bar']}>
          <input
            type="text"
            placeholder="Find a course..."
            name="searchResult"
            value={searchResult}
            onChange={(e) => setSearchResult(e.target.value)}
          />
          <IoIosSearch id={classes['font']} />
        </div>
      </div>

      <div className={classes['flex-courses']}>
        {getPaginatedData().map((post) => (
          <article
            className={classes['flex-courses-column']}
            key={post.id}
          >
            <div className={classes['flex-courses-image-container']}>
              {post.id === videoIndex ? (
                <img
                  src={post.img}
                  alt="img thumbnail"
                  style={{ display: 'none' }}
                />
              ) : (
                <React.Fragment>
                  <img
                    src={post.img}
                    alt="img thumbnail"
                  />
                  <div
                    className={classes['play-icon-body']}
                    onClick={() => setVideoIndex(post.id)}
                  >
                    <BsFillPlayFill className={classes['play-icon']} />
                  </div>
                </React.Fragment>
              )}
              {post.id === videoIndex ? (
                <ReactPlayer
                  controls={true}
                  className={classes['react-player']}
                  url={post.vid}
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        onContextMenu: (e) => e.preventDefault(),
                      },
                    },
                  }}
                />
              ) : null}
            </div>

            <div className={classes['hyperlink-flex']}>
              <a href={post.link}>{post.name}</a>
              <MdOutlineArrowOutward className={classes['hyperlink-icon']} />
            </div>
          </article>
        ))}
      </div>

      {dataLimit < data.length && (
        <button onClick={loadMore} className={classes["load-more"]}>
          <AiOutlineArrowDown className={classes['button-icon']} /> Load More
        </button>
      )}
    </section>
  );
};

export default Pagination;
