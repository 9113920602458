import React, { Component } from 'react';
import classes from './Footer.module.css';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/company_logo/company logo dark.svg';

class Footer extends Component {
  render() {
    let currentYear = new Date().getUTCFullYear();
    return (
      <section className={classes['footer-container']}>
        <section className={classes['first-section']}>
          <div>
            <h5>Join our newsletter</h5>
            <p>We’ll send you a nice letter once per week. No spam.</p>
          </div>

          <form>
            <input
              id="email"
              type="text"
              name="email"
              autoComplete="off"
              placeholder="Enter your email"
            />
            <button>Subscribe</button>
          </form>
        </section>

        <hr />

        <section className={classes['second-section']}>
          <div>
            <h3>Company</h3>
            <ul>
              <li>About us</li>
              <NavLink
                to="/contact"
                style={{ textDecoration: 'none' }}
              >
                <li>Contact Us</li>
              </NavLink>
              <NavLink
                to="/pricing"
                style={{ textDecoration: 'none' }}
              >
                <li>Pricing</li>
              </NavLink>
              <a
                href="https://app.ilearncloud.io/login"
                style={{ textDecoration: 'none' }}
                target="_blank"
                referrerPolicy="no-referrer"
                rel="noreferrer"
              >
                <li>
                  Coding Challenge <span>New</span>
                </li>
              </a>
              <li>iLearn Cloud for business</li>
            </ul>
          </div>

          <div>
            <h3>Categories</h3>
            <ul>
              <li>AWS</li>
              <li>AZURE</li>
              <li>Google Cloud</li>
              <li>Linux</li>
              <li>Software Development</li>
              <li>Data science & Analytics</li>
            </ul>
          </div>
        </section>

        <hr />

        <section className={classes['third-section']}>
          <div>
            <img
              src={logo}
              alt="logo"
            />
          </div>

          <p>
            &copy; {currentYear} iLearn Cloud. All rights reserved. Powered by
            CloudPlexo.
          </p>
        </section>
      </section>
    );
  }
}

export default Footer;
