import React, { useState, useEffect } from 'react';
import './homepage.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Link } from 'react-router-dom';
import classes from './Homepage.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

import { posts } from './posts';
import Nav from '../../utils/nav/Nav';
import Footer from '../../utils/footer/Footer';
import Clients from '../../utils/clients/clients';
import LanderFAQ from '../../utils/faq-lander/faq';
import { LandingPage } from '../../utils/facebookEvent';
import Courses from '../../utils/pagination/Pagination';

// importing section images
import Bash from '../../../assets/bash-home.png';
import CLang from '../../../assets/c++-home.png';
import Python from '../../../assets/python-home.png';
import JavaScript from '../../../assets/javascript-home.png';
import videoImage from '../../../assets/herosection image.png';
import Playground from '../../../assets/playground-home.webp';

const Homepage = () => {
  const [slider, setSlider] = useState(null);
  const [initialCourses, setInitialCourses] = useState(12);

  // get posts in reverse order
  const getNewVideosFirst = [...posts].reverse();

  useEffect(() => {
    LandingPage();
  });

  function loadMore() {
    setInitialCourses((prev) => Math.min(prev + 4, getNewVideosFirst.length));
  }

  // slider function handlers
  function next() {
    slider.slickNext();
  }

  function previous() {
    slider.slickPrev();
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Nav />

      <section className={classes['hero-section']}>
        <div className={classes['homepage-left-herosection']}>
          <h1 className={classes['homepage-left-herosection-title']}>
            Seize Control of Your Journey with Cloud Mastery.
          </h1>
          <p className={classes['homepage-left-herosection-subtitle']}>
            Attain expertise in cloud technology through ilearncloud's
            industry-leading training in AWS, Azure, and Google Cloud.
          </p>

          <div>
            <a
              href="https://app.ilearncloud.io/signup"
              className={classes['homepage-left-herosection-button']}
            >
              Get Started for free
            </a>
            <Link
              to="/business"
              className={classes['homepage-left-herosection-button']}
            >
              Try iLearncloud for business
            </Link>
          </div>
        </div>

        <div className={classes['homepage-video-herosection']}>
          <img
            src={videoImage}
            alt="Ilearn Cloud homepage"
          />
        </div>
      </section>

      <Clients />

      {/* List of courses section */}
      <Courses
        loadMore={loadMore}
        data={getNewVideosFirst}
        dataLimit={initialCourses}
      />

      {/* Coding Section */}
      <div className={classes['coding-section']}>
        <div className={classes['img-playground']}>
          <img
            src={Playground}
            alt="coding playgorund img"
          />
        </div>

        <div className={classes['languages']}>
          <h2> Learn with Practice</h2>
          <p>
            Through our innovation and coding challenges available in several
            programming languages. Top coders win exciting rewards every week.
            Improve your skills, prepare for technical interviews and more
          </p>

          <div className={classes['language-icons']}>
            <img
              src={Bash}
              alt="bash language icon"
            />
            <img
              src={CLang}
              alt="c++ language icon"
            />
            <img
              src={Python}
              alt="python language icon"
            />
            <img
              src={JavaScript}
              alt="javascript language icon"
            />
          </div>
        </div>
      </div>

      {/* Customers Reviews section */}
      <div className="feedback-slider">
        <div className="slider-heading">
          <div>
            <h2 className="reviews-heading">Alumni Feedback</h2>
            <p className="reviews-subheading mb-4">
              Our alumni's trust and stories speak volumes.
            </p>
          </div>

          <div className="custom-arrows">
            <button
              type="button"
              onClick={previous}
            >
              <AiOutlineArrowLeft id="font" />
              Previous
            </button>
            <button
              type="button"
              onClick={next}
            >
              Next
              <AiOutlineArrowRight id="font" />
            </button>
          </div>
        </div>

        {/* Feedback section */}
        <section className={classes['feedback']}>
          <Slider
            ref={setSlider}
            {...settings}
          >
            <div>
              <div className="review-box">
                <h3>Bimbo</h3>
                <p>
                  "I enjoyed every bit of my class. I don't regret paying that
                  money, even though it's just day one. Instructor is a
                  brilliant tutor. Wow"
                </p>
              </div>
            </div>

            <div>
              <div className="review-box second-review">
                <h3>Ayodeji</h3>
                <p>
                  "I just finished my first class. I am excited. Awesome
                  facilitator. I am sure I would be able to learn a lot. He is
                  also able to give relatable examples to my field"
                </p>
              </div>
            </div>

            <div>
              <div className="review-box">
                <h3>Pelumi</h3>
                <p>
                  "My class went well. The tutor really did justice to today's
                  training. He used simple words I would be able to understand.
                  I really enjoyed the training. Thank you"
                </p>
              </div>
            </div>
          </Slider>
        </section>

        <LanderFAQ />

        <section className={classes['why-choose-ilearncloud']}>
          <h3>Why choose iLearn Cloud</h3>

          <p>
            We offer a wide range of high-quality courses taught by experienced
            instructors. <br /> With ilearncloud, you have the convenience of
            learning at your own pace and on your own schedule, making it easy
            to fit education into your busy life.
          </p>

          <div className={classes['why-choose-ilearncloud-button-group']}>
            <a href="https://app.ilearncloud.io/signup">Get Started for free</a>
            <Link to="/business">Enquire for more information</Link>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
